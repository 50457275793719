import { ScreenSizeEnum } from "yper-js-libs";
var HeaderStyles = /** @class */ (function () {
    function HeaderStyles() {
        this.$dropdown = $('.dropdown');
        this.$headerContent = $(".header--main");
        this.$dropdownChild = '.dropdown-menu';
        this.$burgerBtn = $('.burger-click-region');
        this.$navLink = $(".nav-link");
        this.menuResponsive();
        this.headerOnScroll();
        this.responsiveNavbarAnim();
    }
    /**
     * Display responsive menu/regular menu
     */
    HeaderStyles.prototype.menuResponsive = function () {
        var _this = this;
        var $windowsScreen = $(window);
        this.$dropdown.on('hide.bs.dropdown', function (e) {
            $(e.currentTarget).find('.dropdown-menu').first().stop(true, true).slideUp(200);
        });
        this.$dropdown.on('show.bs.dropdown', function (e) {
            $(e.currentTarget).find('.dropdown-menu').first().stop(true, true).slideDown(300);
        });
        this.$navLink.on("click", function (e) {
            $(e.currentTarget).find("i").toggleClass("active--link");
        });
        $windowsScreen.on('resize', function (e) {
            if ($windowsScreen.width() > ScreenSizeEnum.xlBreakPoint) {
                _this.$dropdown.on('mouseover', function (e) {
                    $(e.currentTarget).find(_this.$dropdownChild).stop(true, true).slideDown(150);
                });
                _this.$dropdown.on('mouseleave', function (e) {
                    $(e.currentTarget).find(_this.$dropdownChild).stop(true, true).slideUp(105);
                });
            }
        });
        if ($windowsScreen.width() > ScreenSizeEnum.xlBreakPoint) {
            $('.dropdown').on('hide.bs.dropdown', function (e) {
                $(this).find('.dropdown-menu').first().stop(true, true).slideUp(200);
            });
            this.$dropdown.on('mouseover', function (e) {
                $(e.currentTarget).find(_this.$dropdownChild).stop(true, true).slideDown(150);
            });
            this.$dropdown.on('mouseleave', function (e) {
                $(e.currentTarget).find(_this.$dropdownChild).stop(true, true).slideUp(105);
            });
        }
    };
    HeaderStyles.prototype.responsiveNavbarAnim = function () {
        var clickDelay = 500, clickDelayTimer = null;
        this.$burgerBtn.on('click', function (e) {
            if (clickDelayTimer === null) {
                var $burger = $(e.currentTarget);
                $burger.toggleClass('active');
                $burger.parent().toggleClass('is-open');
                if (!$burger.hasClass('active')) {
                    $burger.addClass('closing');
                }
                clickDelayTimer = setTimeout(function (e) {
                    $burger.removeClass('closing');
                    clearTimeout(clickDelayTimer);
                    clickDelayTimer = null;
                }, clickDelay);
            }
        });
    };
    HeaderStyles.prototype.headerOnScroll = function () {
        var _this = this;
        var scrollTop = 50;
        $(window).on("scroll", function (e) {
            if ($(window).scrollTop() > scrollTop) {
                _this.$headerContent.addClass("active");
            }
            else {
                _this.$headerContent.removeClass("active");
            }
        });
    };
    return HeaderStyles;
}());
export { HeaderStyles };
