import { HeaderStyles } from "./headerStyles";
var Header = /** @class */ (function () {
    function Header() {
        /**
         * Header style behavior
         */
        this.HeaderStyles = new HeaderStyles();
    }
    return Header;
}());
$(function () {
    new Header();
});
